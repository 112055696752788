import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../constants';
import getTagValue from './get-tag-value';
import searchFilter from '../searchFilter';
import {isSet} from '../utility';

const getServiceUrl = ({description, id, slug, warp, location, tags = []}) => {
    const serviceTags = description && description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) || [];
    const isNewService = !!getTagValue(serviceTags, 'theme') || getTagValue(tags, 'constructor') === 'new' || getTagValue(tags, 'constructor') === 'ad';
    const fancyPath = getTagValue(serviceTags, 'fancy', {defaultValue: ''});
    const getFancyPath = () => {
        return `${fancyPath.includes('services') ? `${fancyPath}${searchFilter(location.search)}` : `${fancyPath.includes('events') ? '' : '/services'}${fancyPath}`}/${slug ? encodeURIComponent(slug) : id}${searchFilter(location.search)}`;
    };
    const getDefaultPath = () => {
        return isSet(warp) ?
            `/services/warp/${slug ? encodeURIComponent(slug) : id}${searchFilter(location.search)}`
            : `/services/${id}${searchFilter(location.search)}`;
    };
    const getNewServicePath = () => {
        return `/events/${slug ? encodeURIComponent(slug) : id}${searchFilter(location.search)}`;
    };
    const serviceUrl = () => {
        return fancyPath ? getFancyPath() : isNewService ? getNewServicePath() : getDefaultPath();
    };
    return serviceUrl();
};

export default getServiceUrl;